<template>
  <div>
    <el-button @click="initBSP">login</el-button>
  </div>
</template>
<script>

export default {
  data() {
    return {
    };
  },
  methods: {
    test(){


      const login = () => {
        if(!window.FB) return;
        // 检查用户登录状态
        window.FB.getLoginStatus(function (response) {
          if (response.status !== 'connected') {
            // 未登录，fb会让用户登录
            window.FB.login((res) => {
              // 不管用户有没有登录都会有res
              console.log('FB.login', res)
            },{scope: 'pages_show_list,pages_messaging,pages_read_user_content,pages_read_engagement,pages_manage_metadata'})
          } else {
            // 已登录过，fb直接返回response
            console.log('FB.getLoginStatus已登录', response)
          }
        })
      }
      window.fbAsyncInit = function() {
        window.FB.init({
          appId      : '1843894032712435',
          cookie     : true,
          xfbml      : true,
          version    : 'v18.0'
        });

        window.FB.AppEvents.logPageView();


        login();
      };

      (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
      login();
      console.log('------------------------facebook-jssdk--------------------------------')

// --------------------------------------------------------

      // FB.getLoginStatus(function(response) {
      //   statusChangeCallback(response);
      // });

      //  <fb:login-button
      //      config_id="{config_id}"
      //      onlogin="checkLoginState();">
      //  </fb:login-button>


      // function checkLoginState() {
      //   FB.getLoginStatus(function(response) {
      //     statusChangeCallback(response);
      //   });
      // }
      // {
      //   status: 'connected',
      //       authResponse: {
      //   accessToken: '...',
      //       expiresIn:'...',
      //       signedRequest:'...',
      //       userID:'...'
      // }
      // }
    },
    initBSP() {
      this.test();
      // window.FB.login(
      //     function (response) {
      //       console.log(response);
      //       if(response.status==='connected'){
      //         const userToken= window.FB.getAccessToken();
      //         console.log(userToken)
      //         // debug_token
      //       }
      //     },
      //     {
      //       config_id: '1495044784694731',
      //       response_type: 'code',
      //       override_default_response_type: true
      //     }
      // );
    }
  },
};
</script>
